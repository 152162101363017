import { useRoutes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import React, { Suspense } from 'react';
import { MatxTheme } from "./components";
// ALL CONTEXTS
import { AuthProvider } from "./contexts/JWTAuthContext";
import SettingsProvider from "./contexts/SettingsContext";
// ROUTES
import routes from "./routes";
// FAKE SERVER
import "../fake-db";

const UserDashboard =
  process.env.REACT_APP_ENABLE_USER === "true"
    ? React.lazy(() => import("./parent-components/UserDashboard"))
    : null;

const EvaluatorDashboard =
  process.env.REACT_APP_ENABLE_EVALUATOR === "true"
    ? React.lazy(() => import("./parent-components/EvaluatorDashboard"))
    : null;

const InstituteDashboard =
  process.env.REACT_APP_ENABLE_INSTITUTE === "true"
    ? React.lazy(() => import("./parent-components/InstituteDashboard"))
    : null;

export default function App() {
  const content = useRoutes(routes);
  const role = process.env.REACT_APP_ROLE;
  let RoleComponent;

  // if (role === 'admin') RoleComponent = AdminDashboard;
  if (role === 'user' || role === 'default') RoleComponent = UserDashboard;
  else if (role === 'evaluator') RoleComponent = EvaluatorDashboard;
  else if (role === 'institute') RoleComponent = InstituteDashboard;
  console.log("comp::", process.env.REACT_APP_ENABLE_EVALUATOR === "true");
  console.log("comp1::", role);
  return (
    <SettingsProvider>
      <AuthProvider>
        <MatxTheme>
          {/* <CssBaseline />
          {content} */}
          <Suspense fallback={<div>Loading...</div>}>
            {RoleComponent ? <RoleComponent><CssBaseline />{content}</RoleComponent> : <div>No role matched!</div>}
          </Suspense>
        </MatxTheme>
      </AuthProvider>
    </SettingsProvider>
  );
}
