import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { FormContext } from "../../contexts/UserContext/FormContextProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserBaseURL } from "app/contexts/JWTAuthContext";

const Step6Payment = () => {
    const { state, dispatch } = useContext(FormContext);
    const [promoCode, setPromoCode] = useState("");
    const userId = sessionStorage.getItem("userId");
    const navigate = useNavigate();
    // Calculate payment details dynamically
    useEffect(() => {
        dispatch({
            type: "UPDATE_PAYMENT_BREAKDOWN",
            payload: {
                isCourierDelivery: state.recipientDetails.isCourierDelivery,
                promoCode: state.paymentInfo.promoCode,
            },
        });
    }, [state.recipientDetails.isCourierDelivery, state.paymentInfo.promoCode, dispatch]);

    const { paymentBreakdown } = state.paymentInfo;

    const handlePayment = () => {
        console.log("Redirecting to payment gateway...");
    };

    const handleApplyPromoCode = () => {
        dispatch({
            type: "UPDATE_PAYMENT_INFO",
            payload: { promoCode: promoCode },
        })
    }

    const handleNextStep = async () => {
        console.log("step6 payload:", { applicationId: state.applicantDetail.id, ...state.paymentInfo, userId })
        // dispatch({ type: "UPDATE_STEP", payload: state.step + 1 });
        let payload = {
            applicationId: state.applicantDetail.id,
            userId: userId,
            orderId: "ORD1234567890",
            amount: String(state.paymentInfo.paymentBreakdown.total),
            currency: "USD",
            paymentMethod: "CREDIT CARD",
            paymentStatus: "COMPLETED",
            transactionId: "4ba5aaf6-e5d2-41fd-8c2c-f35e98576ece",
            promoCode: promoCode
        }
        try {
            await axios.post(`${UserBaseURL}/application/payment-details`, payload).then((res) => {
                console.log("step6 response:", res.data);
                dispatch({ type: "RESET_FORM" });
                navigate("/user/dashboard");
                // if (searchParams.get("edit")) {
                //     dispatch({ type: "UPDATE_APPLICANT", payload: res.data });
                //     dispatch({ type: "UPDATE_STEP", payload: 4 });
                //     navigate("/user/application-form", { replace: true });
                // } else {
                //     // api call here post
                //     dispatch({ type: "UPDATE_APPLICANT", payload: res.data });
                //     dispatch({ type: "UPDATE_STEP", payload: state.step + 1 });
                // }
            }).catch((err) => {
                console.error("Error saving data:", err.response);
                // alert("Failed to save data. Please try again.");
            });
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <Box sx={{ width: "100%", p: 2 }}>
            <Typography variant="h6">Payment Details</Typography>
            <Typography>Report Charges: ${paymentBreakdown?.reportCharge || 0}</Typography>
            <Typography>
                Courier Charges: ${paymentBreakdown?.courierCharge || 0}
            </Typography>
            <Typography>Discount: -${paymentBreakdown?.discount || 0}</Typography>
            <Typography>
                <strong>Total: ${paymentBreakdown?.total || 0}</strong>
            </Typography>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <TextField
                    label="Promo Code"
                    value={promoCode}
                    onChange={(e) =>
                        // dispatch({
                        //     type: "UPDATE_PAYMENT_INFO",
                        //     payload: { promoCode: e.target.value },
                        // })
                        setPromoCode(e.target.value)
                    }
                    margin="normal"
                    size="small"
                />
                <Button
                    variant={promoCode ? "contained" : "text"}
                    // onClick={handlePayment}
                    onClick={handleApplyPromoCode}
                    size="medium"
                    color="success"
                    sx={{ height: "100%", marginTop: "8px" }}
                >
                    Apply
                </Button>
            </Box>
            <Box sx={{ mt: 2, mx: 0, display: "flex", gap: 2 }}>
                <Button
                    disabled={state.step === 0}
                    variant="outlined"
                    onClick={() => dispatch({ type: "UPDATE_STEP", payload: state.step - 1 })}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    // onClick={() =>
                    // (dispatch({ type: "UPDATE_STEP", payload: Math.min(state.step + 1, steps.length - 1) }),
                    //     state.step === steps.length - 1 && navigate('/dashboard'))
                    // }
                    onClick={handleNextStep}
                >
                    {"Next"}
                </Button>
            </Box>

        </Box>
    );
};

export default Step6Payment;
