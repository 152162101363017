import React, { useState, Fragment } from 'react';
import {
    Card, CardContent, Typography, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Checkbox, IconButton,
    TablePagination, TableSortLabel, styled, Box, Button, TextField, Menu, MenuItem, InputLabel, FormControl, Select, Dialog, DialogContent
} from '@mui/material';
import { FilterList, Done, Clear, TrendingFlat, Start, RemoveRedEye, Send } from '@mui/icons-material';

const StyledRoot = styled("div")(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#76B3D8",
    minHeight: "100%",
    padding: "1rem",
    // marginTop: "5rem",
    "& .card-container": {
        marginTop: "85px",
        width: "100%",
        maxWidth: "1200px",
        // backgroundColor: "rgba(0, 0, 0, 0.2)",
        backgroundColor: "#e9e9e9",
        padding: "1rem",
        boxSizing: "border-box",
    },
    "& .application-card": {
        width: "100%",
        marginBottom: "1rem",
        padding: "1rem",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
    },
    "& .card-header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap", // Wrap on smaller screens
        gap: "1rem",
        marginBottom: "2rem"
    },
    "& .status": {
        padding: "4px 8px",
        borderRadius: "4px",
        color: "#fff",
    },
    "@media (max-width: 768px)": {
        padding: "1rem",
        marginTop: "2rem",
        "& .card-header": {
            // flexDirection: "column", // Stack on smaller screens
            // alignItems: "flex-start",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        "& .application-card": {
            padding: "0.8rem",
        },
        "& .card-container": {
            padding: "0.5rem",
        },
    },
    "@media (max-width: 480px)": {
        "& .card-header": {
            alignItems: "stretch",
        },
        "& .application-card": {
            padding: "0.5rem",
        },
    },
}));

const MainPage = () => {
    const [orders1, setOrders1] = useState([
        {
            srNo: 1,
            referenceId: "Ref145236",
            document: "Bank statement",
            status: "Pending",
            // reportStatus:
            date: "Dec 5"
        },
        {
            srNo: 2,
            referenceId: "Ref1452698",
            document: "Bank statement",
            status: "Approved",
            date: "Dec 5"
        },
        {
            srNo: 3,
            referenceId: "REF45896",
            document: "Bank statement",
            status: "Rejected",
            date: "Dec 5"
        },
        {
            srNo: 4,
            referenceId: "REF47856",
            document: "Bank statement",
            status: "Under Review",
            date: "Dec 5"
        },
    ]);
    const [filterStatus, setFilterStatus] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [iframeSrc, setIframeSrc] = useState('');

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleStatusChange = (status) => {
        setFilterStatus(status);
        setAnchorEl(null);
    };
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleStatusChange1 = (orderId, status) => {
        setOrders1((prevOrders) =>
            prevOrders.map((order) =>
                order.id === orderId ? { ...order, status } : order
            )
        );
    };
    const handleOpenDialog = (src) => {
        setIframeSrc(src);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setIframeSrc('');
    };
    const filteredData = orders1.filter((row) => {
        return (
            (filterStatus === '' || row.status === filterStatus) &&
            (searchTerm === '' || row.referenceId.toLowerCase().includes(searchTerm.toLowerCase()))
        );
    });
    return (
        <Fragment>
            <StyledRoot>

                <Box className="card-container">
                    {/* <Box className="card-header">
                        <Typography variant="h4">My Applications</Typography>
                        <Button variant="contained" color="primary">
                            Create New Application
                        </Button>
                    </Box> */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                        <TextField
                            label="Search by RefID"
                            variant="outlined"
                            value={searchTerm}
                            onChange={handleSearch}
                            sx={{ width: '40%' }}
                        />

                        <Box>
                            <IconButton onClick={handleMenuOpen}>
                                <FilterList />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={() => handleStatusChange('')}>All</MenuItem>
                                <MenuItem onClick={() => handleStatusChange('Pending')}>Pending</MenuItem>
                                <MenuItem onClick={() => handleStatusChange('Approved')}>Approved</MenuItem>
                                <MenuItem onClick={() => handleStatusChange('Rejected')}>Rejected</MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                    <Card >
                        <CardContent>
                            {/* <div>
                                <Typography variant="h6">All Applications</Typography>
                            </div> */}
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center"><TableSortLabel>Sr No</TableSortLabel></TableCell>
                                            <TableCell align="center"><TableSortLabel>Ref Id</TableSortLabel></TableCell>
                                            <TableCell align="center"><TableSortLabel>Document</TableSortLabel></TableCell>
                                            <TableCell align="center">
                                                <TableSortLabel>Status</TableSortLabel>
                                            </TableCell>
                                            <TableCell align="center">
                                                <TableSortLabel>Date</TableSortLabel>
                                            </TableCell>
                                            <TableCell align="center"><TableSortLabel>Report Status</TableSortLabel></TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredData
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((order, idx) => (
                                                <TableRow key={order.id}>
                                                    {/* <TableCell padding="checkbox">
                                            <Checkbox />
                                        </TableCell> */}
                                                    <TableCell align="center">{idx + 1}</TableCell>
                                                    <TableCell align="center">{order.referenceId}</TableCell>
                                                    <TableCell align="center">{order.document}</TableCell>
                                                    <TableCell align="center"><span
                                                        style={{
                                                            backgroundColor:
                                                                order.status === 'Approved'
                                                                    // ? 'rgba(9, 182, 109, 1)'
                                                                    ? "#28a745"
                                                                    : order.status === 'Rejected'
                                                                        // ? '#FF3D57'
                                                                        ? "#dc3545" : order.status === "Under Review" ? "#ffc107" : "#ffc107",
                                                            // : '#FFAF38',
                                                            padding: '4px 8px',
                                                            borderRadius: '4px',
                                                            color: '#fff',
                                                        }}
                                                    >
                                                        {order.status}
                                                    </span></TableCell>
                                                    <TableCell align="center">{order.date}</TableCell>
                                                    <TableCell align="center">
                                                        <span
                                                            style={{
                                                                backgroundColor:
                                                                    '#007bff',
                                                                padding: '4px 8px',
                                                                borderRadius: '4px',
                                                                color: '#fff',
                                                                // opacity: order.status === "Under Review" ? 0.5 : 1,
                                                                cursor: order.status === "Under Review" ? "no-drop" : "pointer"
                                                            }}

                                                        >
                                                            <IconButton
                                                                // aria-label="Mark as Delivered"
                                                                onClick={() => handleOpenDialog(order?.reportFile)}
                                                            >
                                                                <RemoveRedEye color='#fff' />
                                                                {/* {order.status === "Approved" || order.status === "Under Review" ? <RemoveRedEye color='#fff' /> : <Send />} */}
                                                            </IconButton>
                                                            {"View"}
                                                            {/* {order.status === 'Approved'
                                                                ? "View"
                                                                : order.status === 'Rejected'
                                                                    ? "Send" : order.status === "Under Review" ? "View" : "Send"} */}
                                                        </span>
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                    </TableBody>

                                </Table>
                            </TableContainer>

                            {/* Pagination */}
                            <TablePagination
                                component="div"
                                count={orders1.length}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                rowsPerPageOptions={[5, 10, 25]}
                                labelRowsPerPage="Rows per page:"
                            />
                            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
                                <DialogContent>
                                    <iframe
                                        src={iframeSrc}
                                        title="Report Viewer"
                                        style={{ width: '100%', height: '80vh', border: 'none' }}
                                    ></iframe>
                                </DialogContent>
                            </Dialog>
                        </CardContent>
                    </Card>
                </Box>
            </StyledRoot>
        </Fragment>
    )
}

export default MainPage