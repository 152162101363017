import React from "react";
import {
  Card,
  Checkbox,
  Grid,
  TextField,
  Box,
  styled,
  useTheme,
  Button,
} from "@mui/material";
import {
  TopbarRoot,
  TopbarContainer,
} from "app/components/MatxLayout/Layout1/Layout1Topbar";
import useAuth from "app/hooks/useAuth";

import "app/style/header.css";
import { useNavigate } from "react-router-dom";

const HeaderUBV = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const userId = sessionStorage.getItem("userId");
  const token = sessionStorage.getItem("token");
  return (
    <div>
      <TopbarRoot>
        <TopbarContainer>
          <Box className="hedaer-container">
            <Box className="header-wrapper">
              {/* logo */}
              <Box className="header-logo">
                <a
                  href="https://applications.wes.org/createaccount/login/login"
                  target="_blank"
                >
                  <img
                    src="/assets/ubv-images/logo/ubvLogo2.svg"
                    alt="UBV Logo"
                  />
                  {/* Logo */}
                </a>
              </Box>
              {/* name of page */}
              {/* <Box className="header-content">
                
              My Account
              
            </Box> */}
              {(userId || token) &&
                <Box>
                  <Button onClick={() => logout()} variant="outlined">Logout</Button>
                </Box>
              }
            </Box>
          </Box>
        </TopbarContainer>
      </TopbarRoot>
    </div >
  );
};

export default HeaderUBV;
