import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TextField, MenuItem, Box, Button, Typography, Grid, FormControlLabel, Checkbox } from "@mui/material";
import { FormContext } from "../../contexts/UserContext/FormContextProvider";
import axios from "axios";
import { UserBaseURL } from "app/contexts/JWTAuthContext";

const genders = ["Male", "Female", "Transgender", "Others", "Prefer not to say"];

const Step1PersonalInfo = () => {
    const { state, dispatch } = useContext(FormContext);
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const [errors, setErrors] = useState({});
    const [selectedCountryCode, setSelectedCountryCode] = useState('+91');
    const userId = sessionStorage.getItem("userId") || "23184724-ce70-43a5-a711-7a36ac80f62c";
    const handleCountryCodeChange = (e) => {
        setSelectedCountryCode(e.target.value);
    };
    const handleChange = (field, value) => {
        dispatch({
            type: "UPDATE_PERSONAL_INFO",
            payload: { [field]: value },
        });
    };
    const validateFields = () => {
        const newErrors = {};
        const { firstName, lastName, email, contactNumber, dateOfBirth } = state.personalInfo;

        if (!firstName) newErrors.firstName = "First name is required.";
        // if (!state.personalInfo.noLastName)
        //     newErrors.lastName = "Last name is required.";
        if (!email) newErrors.email = "Email is required.";
        else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = "Invalid email format.";
        if (!contactNumber) newErrors.contactNumber = "Phone number is required.";
        else if (!/^\d{10}$/.test(contactNumber)) newErrors.contactNumber = "Invalid contactNumber number.";
        if (!dateOfBirth) newErrors.dateOfBirth = "Date of birth is required.";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleNextStep = async () => {
        if (validateFields()) {
            // console.log("Proceed to next step", state.personalInfo);
            // dispatch({ type: "UPDATE_STEP", payload: state.step + 1 });
            let payload = { ...state.personalInfo, contactNumber: `${selectedCountryCode}${state.personalInfo.contactNumber}`, lastName: state.personalInfo.noLastName ? "Unkown" : state.personalInfo.lastName, userId: userId }
            delete payload.noLastName
            delete payload.referralSource
            delete payload.phone
            console.log("Proceed to next", payload)

            // call api here for post then call
            try {
                await axios.post(`${UserBaseURL}/application/personal-details`,
                    payload,
                ).then((res) => {
                    console.log("step1 response:", res.data);
                    if (searchParams.get("edit")) {
                        // call api here for patch then call
                        dispatch({ type: "UPDATE_STEP", payload: 4 });
                        dispatch({ type: "UPDATE_APPLICANT", payload: res.data });
                        navigate("/user/application-form", { replace: true });
                    } else {
                        dispatch({ type: "UPDATE_STEP", payload: state.step + 1 });
                        dispatch({ type: "UPDATE_APPLICANT", payload: res.data });
                    }

                }).catch((err) => {
                    console.error("Error saving data:", err.response);
                    // alert("Failed to save data. Please try again.");
                });
            } catch (error) {
                console.error("Error:", error);
                // alert("An error occurred. Please try again.");
            }
        }
    };

    const fetchUserDetails = async () => {
        let userDetails = {
            // firstName: "John",
            // middleName: "A.",
            // lastName: "Doe",
            // email: "john.doe@example.com",
            // contactNumber: "1234567890",
            // dateOfBirth: "1990-01-01",
            // gender: "Male",
            // heardAboutUs: "Google",
        };
        await axios.get(`${UserBaseURL}/user/${userId}`).then((res) => {
            console.log("getUser::", res.data);
            let data = res.data;
            userDetails = {
                firstName: data.firstName || "",
                middleName: data.middleName || "",
                lastName: data.lastName || "",
                email: data.email || "",
                contactNumber: data.phoneNumber || "",
                dateOfBirth: data.dateOfBirth || "",
                gender: data?.gender || "",
                heardAboutUs: data?.heardAboutUs || "",
            }
        }).catch((err) => {
            console.log("getUserErr::", err.response)
        })
        dispatch({
            type: "UPDATE_PERSONAL_INFO",
            payload: userDetails,
        });
    };
    useEffect(() => {
        !searchParams.get("edit") && fetchUserDetails();
    }, [dispatch]);
    useEffect(() => {
        if (searchParams.get("edit")) {
            dispatch({
                type: "UPDATE_PERSONAL_INFO",
                payload: state.personalInfo,
            });
        }
    }, [])

    return (
        <Box sx={{ width: "100%", p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="First Name"
                        value={state.personalInfo.firstName}
                        onChange={(e) => handleChange("firstName", e.target.value)}
                        fullWidth
                        margin="normal"
                        error={Boolean(errors.firstName)}
                        helperText={errors.firstName}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Middle Name"
                        value={state.personalInfo.middleName}
                        onChange={(e) => handleChange("middleName", e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Last Name"
                        value={state.personalInfo.lastName}
                        onChange={(e) => handleChange("lastName", e.target.value)}
                        fullWidth
                        margin="normal"
                        error={Boolean(errors.lastName)}
                        helperText={errors.lastName}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.personalInfo.noLastName}
                                onChange={() =>
                                    handleChange("noLastName", !state.personalInfo.noLastName)
                                }
                            />
                        }
                        label="Do not have a Last Name"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Email"
                        value={state.personalInfo.email}
                        onChange={(e) => handleChange("email", e.target.value)}
                        fullWidth
                        margin="normal"
                        error={Boolean(errors.email)}
                        helperText={errors.email}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Date of Birth"
                        name="dateOfBirth"
                        type="date"
                        value={state.personalInfo.dateOfBirth}
                        onChange={(e) => handleChange("dateOfBirth", e.target.value)}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        error={Boolean(errors.dateOfBirth)}
                        helperText={errors.dateOfBirth}
                    />
                </Grid>
                <Grid container item xs={12} sm={4}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            select
                            label="Country Code"
                            value={selectedCountryCode}
                            onChange={handleCountryCodeChange}
                            fullWidth
                            margin="normal"
                        >
                            {/* Add more country codes as needed */}
                            <MenuItem value="+1">+1 (US/Canada)</MenuItem>
                            <MenuItem value="+91">+91 (India)</MenuItem>
                            <MenuItem value="+44">+44 (UK)</MenuItem>
                            <MenuItem value="+61">+61 (Australia)</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            label="Phone Number"
                            value={state.personalInfo.contactNumber}
                            onChange={(e) => handleChange("contactNumber", e.target.value)}
                            fullWidth
                            margin="normal"
                            error={Boolean(errors.contactNumber)}
                            helperText={errors.contactNumber}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        select
                        label="Gender"
                        value={state.personalInfo.gender}
                        onChange={(e) => handleChange("gender", e.target.value)}
                        fullWidth
                        margin="normal"
                    >
                        {genders.map((gender) => (
                            <MenuItem key={gender} value={gender}>
                                {gender}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        label="Where did you hear about us?"
                        value={state.personalInfo.heardAboutUs}
                        onChange={(e) => handleChange("heardAboutUs", e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                </Grid>
            </Grid>
            <Box sx={{ mt: 2, mx: 0, display: "flex", gap: 2 }}>
                <Button
                    disabled={state.step === 0}
                    variant="outlined"
                    onClick={() => dispatch({ type: "UPDATE_STEP", payload: state.step - 1 })}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    // onClick={() =>
                    // (dispatch({ type: "UPDATE_STEP", payload: Math.min(state.step + 1, steps.length - 1) }),
                    //     state.step === steps.length - 1 && navigate('/dashboard'))
                    // }
                    onClick={handleNextStep}
                >
                    {"Next"}
                </Button>
            </Box>
            {/* <Button onClick={handleNextStep} variant="contained" sx={{ mt: 2 }}>
                Next Step
            </Button> */}
        </Box>
    );
};

export default Step1PersonalInfo;
