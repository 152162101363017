export const initialState = {
    step: 0,
    applicantDetail: {},
    personalInfo: {
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        contactNumber: "",
        dateOfBirth: "",
        gender: "",
        heardAboutUs: "",
        noLastName: false
    },
    applicationPurpose: [],
    otherPurpose: "",
    // documentDetails: {
    //     bankStatement: null,
    //     balanceConfirmation: null,
    //     relationship: "Self",
    //     purpose: [],
    // },
    bankdetails: {
        country: "",
        bank_name: "",
        branch_name: "",
        ifsc_code: "",
        account_holder: "",
        ac_number: "",
        relation: "",
        other_relation: "", // For when "Other" is selected
    },
    document_uploads:
    {
        doc_type: "",
        file_url: "",
    },
    recipientDetails: {
        recipientType: "Self",
        country: "",
        addressLine1: "",
        addressLine2: "",
        state: "",
        city: "",
        postalCode: "",
        deliveryOption: "",
        isCourierDelivery: false,
        institutes: [],
        universities: []
    },
    paymentInfo: {
        promoCode: "",
        paymentBreakdown: {
            reportCharge: "",
            courierCharge: "",
            discount: "",
            total: "",
        },
        paymentStatus: null,
    },
};

export function formReducer(state, action) {
    switch (action.type) {
        case "UPDATE_STEP":
            return { ...state, step: action.payload };
        case "UPDATE_APPLICANT":
            return { ...state, applicantDetail: action.payload };
        case "UPDATE_PERSONAL_INFO":
            return { ...state, personalInfo: { ...state.personalInfo, ...action.payload } };
        case "UPDATE_PURPOSE":
            return { ...state, applicationPurpose: action.payload };
        case "UPDATE_OTHER_PURPOSE":
            return { ...state, otherPurpose: action.payload };
        // case "UPDATE_DOCUMENT_DETAILS":
        //     return { ...state, documentDetails: { ...state.documentDetails, ...action.payload } };
        case "UPDATE_DOCUMENT_TYPE":
            return {
                ...state,
                document_uploads: { ...state.document_uploads, doc_type: action.payload.doc_type }
                // document_uploads: state?.document_uploads?.map((doc) =>
                //     doc.doc_type === action.payload.doc_type ? action.payload : doc
                // ),
            };
        case "UPDATE_DOCUMENT_UPLOAD":
            return {
                ...state,
                document_uploads: { ...state.document_uploads, file_url: action.payload.file_url }
                // document_uploads: [
                //     ...state.document_uploads?.filter((doc) => doc.doc_type !== action.payload.doc_type),
                //     action.payload,
                // ],
            };
        case "UPDATE_BANK_DETAILS":
            return {
                ...state,
                bankdetails: action.payload,
            };
        case "UPDATE_RECIPIENT_DETAILS":
            return { ...state, recipientDetails: { ...state.recipientDetails, ...action.payload } };
        case "PREFETCH_RECIPIENT_DETAILS_":
            return { ...state, recipientDetails: { ...action.payload } };
        case "UPDATE_SELECTED_INSTITUTES":
            return {
                ...state,
                recipientDetails: { ...state.recipientDetails, institutes: action.payload } // Stores the list of selected institutions
            };
        case "UPDATE_SELECTED_UNIVERSITIES":
            return {
                ...state,
                recipientDetails: { ...state.recipientDetails, universities: action.payload } // Stores the list of selected institutions
            };
        case "UPDATE_PAYMENT_INFO":
            return { ...state, paymentInfo: { ...state.paymentInfo, ...action.payload } };
        case "UPDATE_PAYMENT_BREAKDOWN":
            const reportCharge = 95; // Fixed report charge
            const courierCharge = action.payload.isCourierDelivery ? state.recipientDetails.deliveryOption === "standard" ? 12 : state.recipientDetails.deliveryOption === "international" ? 81 : 0 : 0; // Apply courier charge conditionally
            // const discount = action.payload.promoCode === "DISCOUNT10" ? 10 : 0; // Example promo code logic
            const discount = action.payload.promoCode ? action.payload.promoCode.slice(-2) : 0;
            const total = reportCharge + courierCharge - discount;
            console.log("promo::", action.payload.promoCode.slice(-2));

            return {
                ...state,
                paymentInfo: {
                    ...state.paymentInfo,
                    paymentBreakdown: {
                        reportCharge,
                        courierCharge,
                        discount,
                        total,
                    },
                },
            };
        case "RESET_FORM":
            return initialState;
        case "SET_USER_DETAILS":
            return {
                ...state,
                ...action.payload, // Merge the selected application into the state
                step: action.payload.step || 0, // Default to step 0 if not specified
            };
        default:
            return state;
    }
}
