import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Box,
    Typography,
    TextField,
    Button,
    MenuItem,
    Select,
    FormControl,
    FormHelperText,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Grid
} from "@mui/material";
import { FormContext } from "../../contexts/UserContext/FormContextProvider";
import { countryOptions } from "./Step3DocumentUpload";
import axios from "axios";
import { UserBaseURL } from "app/contexts/JWTAuthContext";

const Step4InstitutionDetails = () => {
    const { state, dispatch } = useContext(FormContext);
    const userId = sessionStorage.getItem("userId");
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const [isCourierDelivery, setIsCourierDelivery] = useState(false);
    const [addressDetails, setAddressDetails] = useState({
        country: "",
        addressLine1: "",
        addressLine2: "",
        state: "",
        city: "",
        postalCode: "",
    });
    const [deliveryOption, setDeliveryOption] = useState(state.recipientDetails.deliveryOption || "");
    const [selectedInstitutions, setSelectedInstitutions] = useState([]);
    const [selectedUniversities, setSelectedUniversities] = useState([]);
    const [errors, setErrors] = useState({});
    // Sample data for institutions (Replace with real data)
    const educationInstitutions = {
        USA: [
            { id: "f47ac10b-58cc-4372-a567-0e02b2c3d479", name: "University A" },
            { id: "8ef7252e-4d1a-4aa9-ade8-112cb7075d9e", name: "University B" },
            { id: "f4f213b8-05df-4bfc-9e92-0892c31d34bc", name: "University C" },
        ],
        Canada: [
            { id: "fcf42201-dbf0-40b1-8517-61487cf6ac43", name: "University D" },
            { id: "7a804f66-083c-46ce-a849-e4ff3c5db1cc", name: "University E" },
            { id: "0d097147-e22a-4cb9-b7c1-6f475c5429ed", name: "University F" },
        ],
        UK: [
            { id: "42dc7a71-5b04-4096-a704-7509a3b7793d", name: "University G" },
            { id: "85e2d1a3-a1a4-4202-8ce0-032a4f4056a2", name: "University H" },
            { id: "fa7d0dee-ecaa-4d20-a8b5-ab07144919a8", name: "University I" },
        ],
    };

    const immigrationInstitutions = {
        USA: [
            { id: "854057c4-e1bd-4d77-a010-ab1e8c3d2a74", name: "Immigration Office X" },
            { id: "5046328a-8142-4779-9dd7-e530f67bb16e", name: "Immigration Office Y" },
        ],
        Canada: [
            { id: "25ae24c7-81c2-4524-b10e-efd48ddc62c9", name: "Immigration Office Z" },
            { id: "a3816435-3cba-4e4f-b76c-8d224e0c88cb", name: "Immigration Office W" },
        ],
        UK: [
            { id: "57fad39a-6113-4471-b9b1-1a5c9bf817ac", name: "Immigration Office Q" },
            { id: "7e02daea-84ce-4b9d-a783-b215ed1ca6e5", name: "Immigration Office R" },
        ],
    };

    const handleDeliveryOptionChange = (event) => {
        const selectedOption = event.target.value;
        setDeliveryOption(selectedOption);
        dispatch({
            type: "UPDATE_RECIPIENT_DETAILS",
            // payload: { ...state.recipientDetails, deliveryOption: selectedOption },
            payload: { deliveryOption: selectedOption.toUpperCase() },
        });
    };

    const handleAddressChange = (field, value) => {
        const updatedAddress = { ...addressDetails, [field]: value };
        setAddressDetails(updatedAddress);
        dispatch({
            type: "UPDATE_RECIPIENT_DETAILS",
            // payload: { ...state.recipientDetails, addressDetails: updatedAddress },
            payload: { [field]: value },
        });
    };

    const handleInstitutionChange = (event) => {
        const updatedArr = event.target.value.filter(item => typeof item !== "number");
        setSelectedInstitutions(event.target.value);
        dispatch({
            type: "UPDATE_SELECTED_INSTITUTES",
            // payload: { ...state.recipientDetails, institutes: updatedArr },
            payload: updatedArr
        })
    };
    const handleUniversitiesChange = (event) => {
        const updatedArr = event.target.value.filter(item => typeof item !== "number");
        setSelectedUniversities(event.target.value);
        dispatch({
            type: "UPDATE_SELECTED_UNIVERSITIES",
            // payload: { ...state.recipientDetails, universities: updatedArr },
            payload: updatedArr
        })
    };

    const handleCourierCheckboxChange = (event) => {
        const courier = event.target.checked;
        setIsCourierDelivery(event.target.checked);
        dispatch({
            type: "UPDATE_RECIPIENT_DETAILS",
            payload: { isCourierDelivery: courier },
        });
    };

    const getInstitutions = () => {
        if (state.applicationPurpose.includes("IMMIGRATION")) {
            return immigrationInstitutions[state.selectedCountry || "USA"] || [];
        }
        return [];
    };
    const getUniversities = () => {
        if (state.applicationPurpose.includes("EDUCATION")) {
            return educationInstitutions[state.selectedCountry || "USA"] || [];
        }
        return [];
    };

    const validateFields = () => {
        const newErrors = {};
        if (isCourierDelivery) {
            if (!deliveryOption) newErrors.deliveryOption = "Please selected delivery option";
            if (!addressDetails.country) newErrors.country = "Country is required";
            if (!addressDetails.addressLine1) newErrors.addressLine1 = "Address Line 1 is required";
            if (!addressDetails.state) newErrors.state = "State is required";
            if (!addressDetails.city) newErrors.city = "City is required";
            if (!addressDetails.postalCode) newErrors.postalCode = "Postal Code is required";
        }
        if (state.applicationPurpose.includes("EDUCATION") && selectedUniversities.length === 0) {
            newErrors.universities = "Please select at least one university.";
        }
        if (state.applicationPurpose.includes("IMMIGRATION") && selectedInstitutions.length === 0) {
            newErrors.institutions = "Please select at least one immigration office.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    const handleNextStep = async () => {
        // console.log("step4 payload:", { applicationId: "142536", ...state.recipientDetails })
        let payload = {
            applicationId: searchParams.get("refNo") || state.applicantDetail.id,
            deliveryOptions: state.recipientDetails.deliveryOption.toUpperCase(),
            country: state.recipientDetails.country,
            addressLine1: state.recipientDetails.addressLine1,
            addressLine2: state.recipientDetails.addressLine2,
            state: state.recipientDetails.state,
            city: state.recipientDetails.city,
            postalCode: state.recipientDetails.postalCode,
            institutes: state.applicationPurpose.includes("IMMIGRATION") ? state.recipientDetails.institutes : [],
            universities: state.applicationPurpose.includes("EDUCATION") ? state.recipientDetails.universities : [],
            // ...state.recipientDetails
        }
        delete payload.recipientType;
        delete payload.isCourierDelivery;
        delete payload.universitiesSelected;
        delete payload.institutesSelected;
        console.log("step4 payload:", payload)
        if (!validateFields()) {
            return; // Prevent going to the next step
        }
        // if (searchParams.get("edit")) {
        //     // api call here patch
        //     dispatch({ type: "UPDATE_STEP", payload: 4 });
        //     navigate("/user/application-form", { replace: true });
        // } else {
        //     // api call here post
        //     dispatch({ type: "UPDATE_STEP", payload: state.step + 1 });
        // }
        // api call here patch
        try {
            await axios.post(`${UserBaseURL}/application/recipient-details`, payload).then((res) => {
                console.log("step4 response:", res.data);
                if (searchParams.get("edit")) {
                    dispatch({ type: "UPDATE_APPLICANT", payload: res.data });
                    dispatch({ type: "UPDATE_STEP", payload: 4 });
                    navigate("/user/application-form", { replace: true });
                } else {
                    // api call here post
                    dispatch({ type: "UPDATE_APPLICANT", payload: res.data });
                    dispatch({ type: "UPDATE_STEP", payload: state.step + 1 });
                }
            }).catch((err) => {
                console.error("Error saving data:", err.response);
                // alert("Failed to save data. Please try again.");
            });
        } catch (error) {
            console.error("Error:", error);
        }

    };

    useEffect(() => {
        console.log("state", state.recipientDetails)
        console.log("params", searchParams.get("edit"), searchParams.get("refNo"))
        if (searchParams.get("edit")) {
            setIsCourierDelivery(state.recipientDetails.isCourierDelivery);
            setDeliveryOption(state.recipientDetails.deliveryOption);
            setAddressDetails({
                country: state.recipientDetails.country || "",
                addressLine1: state.recipientDetails.addressLine1 || "",
                addressLine2: state.recipientDetails.addressLine2 || "",
                state: state.recipientDetails.state || "",
                city: state.recipientDetails.city || "",
                postalCode: state.recipientDetails.postalCode || "",
            });
            setSelectedUniversities(state.recipientDetails?.universities || []);
            setSelectedInstitutions(state.recipientDetails?.institutes || []);
            dispatch({
                type: "PREFETCH_RECIPIENT_DETAILS_",
                // payload: { ...state.recipientDetails, addressDetails: updatedAddress },
                payload: state.recipientDetails,
            });

        }
    }, [])

    return (
        <Box sx={{ width: "100%", p: 2 }}>
            <Typography variant="h6" mb={2}>Recipient 1 : Self</Typography>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={12}>
                    {/* Courier Delivery Checkbox */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isCourierDelivery}
                                onChange={handleCourierCheckboxChange}
                            />
                        }
                        label="Do you want courier delivery?"
                    />
                </Grid>
                {isCourierDelivery && (
                    <Grid item xs={12} sm={4}>
                        <TextField
                            select
                            label="Delivery Options"
                            value={deliveryOption}
                            onChange={handleDeliveryOptionChange}
                            fullWidth
                            margin="normal"
                            error={!!errors.deliveryOption}
                            helperText={errors.deliveryOption}
                        >
                            <MenuItem value="standard">Standard ($12)</MenuItem>
                            <MenuItem value="international">International ($81)</MenuItem>
                        </TextField>
                    </Grid>
                )}
                {isCourierDelivery && (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography sx={{ fontWeight: "600", mt: 2 }}>Delivery Address</Typography>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                select
                                label="Country"
                                value={addressDetails.country}
                                onChange={(e) => handleAddressChange("country", e.target.value)}
                                fullWidth
                                margin="normal"
                                error={!!errors.country}
                                helperText={errors.country}
                                sx={{ mt: 0 }}
                            >
                                {countryOptions.map((country) => (
                                    <MenuItem key={country} value={country}>
                                        {country}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                label="Address Line 1"
                                variant="outlined"
                                value={addressDetails.addressLine1}
                                onChange={(e) => handleAddressChange("addressLine1", e.target.value)}
                                sx={{ mb: 2 }}
                                error={!!errors.addressLine1}
                                helperText={errors.addressLine1}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                label="Address Line 2"
                                variant="outlined"
                                value={addressDetails.addressLine2}
                                onChange={(e) => handleAddressChange("addressLine2", e.target.value)}
                                sx={{ mb: 2 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                label="State"
                                variant="outlined"
                                value={addressDetails.state}
                                onChange={(e) => handleAddressChange("state", e.target.value)}
                                sx={{ mb: 2 }}
                                error={!!errors.state}
                                helperText={errors.state}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                label="City"
                                variant="outlined"
                                value={addressDetails.city}
                                onChange={(e) => handleAddressChange("city", e.target.value)}
                                error={!!errors.city}
                                helperText={errors.city}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                label="Pincode/Zipcode"
                                variant="outlined"
                                value={addressDetails.postalCode}
                                onChange={(e) => handleAddressChange("postalCode", e.target.value)}
                                sx={{ mb: 2 }}
                                error={!!errors.postalCode}
                                helperText={errors.postalCode}
                            />
                        </Grid>
                    </Grid>
                )}
                {/* <Grid item xs={12} sm={12}></Grid> */}
            </Grid>
            <Box sx={{ my: 3 }}> <hr /></Box>
            {/* Institution Details */}
            <Typography variant="h6" mb={2} >Recipient 2 : Institution's </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    {state.applicationPurpose.includes("EDUCATION") && (
                        <FormControl fullWidth sx={{ mb: 2 }} error={!!errors.universities}>
                            {/* <InputLabel>Select Institution(s)</InputLabel> */}
                            <InputLabel id="demo-simple-select-helper-label">Universities</InputLabel>
                            <Select
                                multiple
                                label="Universities"
                                labelId="demo-simple-select-helper-label"
                                value={selectedUniversities}
                                onChange={handleUniversitiesChange}
                                // renderValue={(selected) => selected.join(", ")}
                                renderValue={(selected) =>
                                    getUniversities()
                                        .filter((uni) => selected.includes(uni.id)) // Match selected IDs
                                        .map((uni) => uni.name) // Display names
                                        .join(", ") // Join names for display
                                }
                            >
                                {getUniversities().map((institution, index) => (
                                    <MenuItem key={institution.id} value={institution.id}>
                                        {institution.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors.universities}</FormHelperText>
                        </FormControl>
                    )}
                    {state.applicationPurpose.includes("IMMIGRATION") && (
                        <FormControl fullWidth sx={{ mb: 2 }} error={!!errors.institutions}>
                            {/* <InputLabel>Select Institution(s)</InputLabel> */}
                            <InputLabel id="demo-simple-select-helper-label">Immigration Office</InputLabel>
                            <Select
                                multiple
                                label="Immigration Office"
                                labelId="demo-simple-select-helper-label"
                                value={selectedInstitutions}
                                onChange={handleInstitutionChange}
                                // renderValue={(selected) => selected.join(", ")}
                                renderValue={(selected) =>
                                    getInstitutions()
                                        .filter((uni) => selected.includes(uni.id)) // Match selected IDs
                                        .map((uni) => uni.name) // Display names
                                        .join(", ") // Join names for display
                                }
                            >
                                {getInstitutions().map((institution, index) => (
                                    <MenuItem key={institution.id} value={institution.id}>
                                        {institution.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors.institutions}</FormHelperText>
                        </FormControl>
                    )}

                </Grid>
            </Grid>
            {/* <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Select Institution(s)</InputLabel>
                <Select
                    multiple
                    value={selectedInstitutions}
                    onChange={handleInstitutionChange}
                    renderValue={(selected) => selected.join(", ")}
                >
                    {getInstitutions().map((institution, index) => (
                        <MenuItem key={index} value={institution}>
                            {institution}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl> */}
            <Box sx={{ mt: 2, mx: 0, display: "flex", gap: 2 }}>
                <Button
                    disabled={state.step === 0}
                    variant="outlined"
                    onClick={() => dispatch({ type: "UPDATE_STEP", payload: state.step - 1 })}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    // onClick={() =>
                    // (dispatch({ type: "UPDATE_STEP", payload: Math.min(state.step + 1, steps.length - 1) }),
                    //     state.step === steps.length - 1 && navigate('/dashboard'))
                    // }
                    onClick={handleNextStep}
                >
                    {"Next"}
                </Button>
            </Box>
        </Box >
    );
};

export default Step4InstitutionDetails;
