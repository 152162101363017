import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Button, TextField, IconButton, Grid } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import FlightIcon from "@mui/icons-material/Flight";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { FormContext } from "../../contexts/UserContext/FormContextProvider";
import { UserBaseURL } from "app/contexts/JWTAuthContext";
import axios from "axios";

const purposes = [
    { label: "Education", icon: <SchoolIcon />, value: "EDUCATION" },
    { label: "Immigration", icon: <FlightIcon />, value: "IMMIGRATION" },
    { label: "Other", icon: <MoreHorizIcon />, value: "OTHER" },
];

const Step2AdditionalInfo = () => {
    const { state, dispatch } = useContext(FormContext);
    const userId = sessionStorage.getItem("userId") || "23184724-ce70-43a5-a711-7a36ac80f62c";
    // const [isOtherSelected, setIsOtherSelected] = useState(state.applicationPurpose.includes("Other"));

    // const handlePurposeClick = (purpose) => {
    //     const updatedPurposes = state.applicationPurpose.includes(purpose)
    //         ? state.applicationPurpose.filter((item) => item !== purpose)
    //         : [...state.applicationPurpose, purpose];

    //     dispatch({
    //         type: "UPDATE_PURPOSE",
    //         payload: updatedPurposes,
    //     });

    //     if (purpose === "Other") {
    //         setIsOtherSelected(!isOtherSelected);
    //     }
    // };

    // const handleOtherPurposeChange = (e) => {
    //     dispatch({
    //         type: "UPDATE_OTHER_PURPOSE",
    //         payload: e.target.value,
    //     });
    // };

    const [error, setError] = useState(false);
    const [selectedPurposes, setSelectedPurposes] = useState(state.applicationPurpose || []);
    const [otherPurpose, setOtherPurpose] = useState(state.otherPurpose || "");
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);

    const handlePurposeToggle = (purpose) => {
        const isSelected = selectedPurposes.includes(purpose);
        let updatedPurposes = [];
        if (isSelected) {
            updatedPurposes = selectedPurposes.filter((item) => item !== purpose);
        } else if (purpose === "OTHER") {
            setSelectedPurposes([purpose]);
            updatedPurposes = [purpose];
        } else if (purpose !== "OTHER") {
            updatedPurposes = [...selectedPurposes, purpose];
        }
        setSelectedPurposes(purpose !== "OTHER" ? updatedPurposes.filter((item) => item !== "OTHER") : updatedPurposes);
        dispatch({
            type: "UPDATE_PURPOSE",
            payload: updatedPurposes,
        });
        setError(false);
    };

    const handleOtherInputChange = (value) => {
        setOtherPurpose(value);
        dispatch({
            type: "UPDATE_OTHER_PURPOSE",
            payload: value,
        });
    };
    const handleNextStep = async () => {

        console.log("Proceed to next step", state.applicationPurpose);
        let payload = { applicationId: searchParams.get("refNo") || state.applicantDetail.id, purpose: state.applicationPurpose, otherPurpose: state.otherPurpose }
        console.log("step2 payload", payload)
        if (selectedPurposes.length === 0) {
            setError(true);
            return;
        }

        // Validation for "Other" option
        if (selectedPurposes.includes("OTHER") && otherPurpose.trim() === "") {
            setError(true);
            return;
        }


        // call api here for post
        try {
            await axios.post(`${UserBaseURL}/application/purpose`,
                payload,
            ).then((res) => {
                console.log("step2 response :", res.data);
                if (searchParams.get("edit")) {
                    // call api here for patch 
                    dispatch({ type: "UPDATE_APPLICANT", payload: res.data });
                    dispatch({ type: "UPDATE_STEP", payload: 4 });
                    navigate("/user/application-form", { replace: true });
                } else {
                    dispatch({ type: "UPDATE_APPLICANT", payload: res.data });
                    dispatch({ type: "UPDATE_STEP", payload: state.step + 1 });
                }

            }).catch((err) => {
                console.error("Error saving data:", err.response);
                // alert("Failed to save data. Please try again.");
            });

        } catch (error) {
            console.error("Error:", error);
            // alert("An error occurred. Please try again.");
        }
        // dispatch({ type: "UPDATE_STEP", payload: state.step + 1 });
    }

    console.log("selected arr:", selectedPurposes)
    console.log("params:", searchParams.get("edit"))

    return (
        <Box sx={{ width: "100%", p: 2, textAlign: "center" }}>
            <Typography variant="h6" gutterBottom>
                Select the purpose of your application
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}>
                {/* Education */}
                <Button
                    variant={selectedPurposes.includes("EDUCATION") && !selectedPurposes.includes("OTHER") ? "contained" : "outlined"}
                    onClick={() => handlePurposeToggle("EDUCATION")}
                    startIcon={<SchoolIcon />}
                    sx={{ width: 150, height: 50 }}
                >
                    Education
                </Button>

                {/* Immigration */}
                <Button
                    variant={selectedPurposes.includes("IMMIGRATION") && !selectedPurposes.includes("OTHER") ? "contained" : "outlined"}
                    onClick={() => handlePurposeToggle("IMMIGRATION")}
                    startIcon={<FlightIcon />}
                    sx={{ width: 150, height: 50 }}
                >
                    Immigration
                </Button>

                {/* Other */}
                <Button
                    variant={selectedPurposes.includes("OTHER") && !(selectedPurposes.includes("EDUCATION") || selectedPurposes.includes("IMMIGRATION")) ? "contained" : "outlined"}
                    onClick={() => handlePurposeToggle("OTHER")}
                    startIcon={<AddCircleOutlineIcon />}
                    sx={{ width: 150, height: 50 }}
                >
                    Other
                </Button>
            </Box>

            {/* Show Input Box if "Other" is Selected */}
            {selectedPurposes.includes("OTHER") && (
                <Box sx={{ mt: 3 }}>
                    <TextField
                        label="Please specify"
                        value={otherPurpose}
                        onChange={(e) => handleOtherInputChange(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                </Box>
            )}
            {error && selectedPurposes.length === 0 && (
                <Typography color="error" sx={{ mt: 2 }}>
                    Please select at least one purpose to proceed.
                </Typography>
            )}
            <Box sx={{ mt: 2, mx: 4, display: "flex", gap: 2 }}>
                <Button
                    disabled={state.step === 0}
                    variant="outlined"
                    onClick={() => dispatch({ type: "UPDATE_STEP", payload: state.step - 1 })}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    // onClick={() =>
                    // (dispatch({ type: "UPDATE_STEP", payload: Math.min(state.step + 1, steps.length - 1) }),
                    //     state.step === steps.length - 1 && navigate('/dashboard'))
                    // }
                    onClick={handleNextStep}
                >
                    {"Next"}
                </Button>
            </Box>
        </Box>
    );
};

export default Step2AdditionalInfo;
