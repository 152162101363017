import { createContext, useReducer, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

export const UserBaseURL = process.env.REACT_APP_USER_URL;

const initialState = {
  user: null,
  userId: null,
  isInitialized: false,
  isAuthenticated: false,
  roles: ['user']
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT":
      return { ...state, isInitialized: true, isAuthenticated: action.payload.isAuthenticated, user: action.payload.user, userId: action.payload.user?.userId || null, role: action.payload.role };
    case "LOGIN":
      return { ...state, isAuthenticated: true, user: action.payload.user, userId: action.payload.user?.userId };
    case "LOGOUT":
      return { ...state, isAuthenticated: false, user: null, userId: null };
    case "REGISTER":
      return { ...state, isAuthenticated: false, userId: action.payload?.userId };
    case "VERIFY":
      return { ...state, isAuthenticated: true, user: action.payload.user, userId: action.payload?.userId };
    case "INIT_INSTITUTES":
      return { ...state, isInitialized: true, isAuthenticated: action.payload.isAuthenticated, user: action.payload.user, userId: action.payload.user?.userId || null, roles: action.payload.roles };
    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => { },
  logout: () => { },
  register: () => { },
  verifyEmail: () => { },
  resendOTP: () => { }
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  console.log("path:", pathname);
  const isInstitute = pathname === "/institute/login";
  useEffect(() => {
    // (async () => {
    //   try {
    //     const { data } = await axios.get("/api/auth/profile");
    //     // const user = { ...data.user, roles: data.user?.roles || [] };
    //     // console.log("Fetched user:", data.user);
    //     // dispatch({ type: "INIT", payload: { ...state, isAuthenticated: true, role: process.env.REACT_APP_ROLE } });
    //     // const { data } = await axios.get("/profile");
    //     dispatch({ type: "INIT", payload: { isAuthenticated: true, user: data.user } })
    //   } catch (err) {
    //     console.error(err);
    //     dispatch({ type: "INIT", payload: { isAuthenticated: false, user: null } });
    //   }
    // })();
    if (isInstitute) {
      console.log("institute auth");
      dispatch({ type: "INIT_INSTITUTES", payload: { isAuthenticated: true, user: null, roles: ['institutes'] } })
    } else {
      dispatch({ type: "INIT", payload: { isAuthenticated: false, user: null } })
    }


  }, []);

  if (!state.isInitialized) return null;

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${UserBaseURL}/auth/login`, { email, password });
      // const { user, token, } = response.data;
      // const response = await axios.post("/api/auth/login", { email, password });
      const { user, token } = response.data
      console.log("login::", response)

      dispatch({ type: "LOGIN", payload: { user } });
      sessionStorage.setItem('token', token);
      sessionStorage.setItem("userId", user.userId);
      setTimeout(() => {
        navigate("/user/dashboard");
      }, 0);
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const register = async (email, country, username, password) => {
    try {
      // const response = await axios.post("/api/auth/register", { email, username, password });
      const response = await axios.post(`${UserBaseURL}/user/initiate`, { email, country });
      const { userId } = response.data;
      console.log("user::", response)
      dispatch({ type: "REGISTER", payload: { userId } });
      sessionStorage.setItem("email", email);
      return true
    } catch (error) {
      console.error("Registration failed:", error);
      return false;
    }
  };

  const verifyEmail = async (otp) => {
    try {
      // const response = await axios.post("/api/auth/register", { email, username, password });
      console.log("OTP being sent:", otp);
      const response = await axios.post(`${UserBaseURL}/auth/verify-otp`, { userId: state.userId, otp });
      const { user } = response.data;
      console.log("verify::", response.data)
      dispatch({ type: "VERIFY", payload: { user: user, userId: state.userId } });
      Swal.fire({ icon: "success", title: "Your email has been successfully verified.", timer: 3000 }).then((res) => {
        sessionStorage.setItem("userId", state.userId);
        navigate("/user/create-account");
      })
    } catch (error) {
      console.error("Email verify failed:", error);
    }
  };

  const resendOTP = async () => {
    try {
      // const response = await axios.post("/api/auth/register", { email, username, password });
      const response = await axios.post(`${UserBaseURL}/auth/resend-otp`, { userId: state.userId });
      const { user } = response.data;
      // dispatch({ type: "VERIFY", payload: { user } });
      // Swal.fire({ icon: "success", title: "Your email has been successfully verified.", timer: 3000 }).then((res) => {
      //   navigate("/")
      // })
      console.log("resend::", user)
    } catch (error) {
      console.error("Email verify failed:", error);
    }
  };

  const logout = async () => {
    try {
      // await axios.post("/api/auth/logout");
      sessionStorage.clear();
      dispatch({ type: "LOGOUT" });
      navigate("/session/signin");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  console.log("initial", state)

  return (
    <AuthContext.Provider value={{ ...state, method: "JWT", login, logout, register, verifyEmail, resendOTP }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

